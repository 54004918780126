<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>Leads list</h6>
        </div>
        <div class="col">
          <button  class="btn bg-gradient-success float-end" @click="$router.push('/users-create')"> Create users</button>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="table-responsive">
        <div class="d-flex justify-content-between">
          <div class="w-25">
            <select name="per_page" id="per_page" v-model="pagination.limit" class="form-control" @click="reloadPaginate(1)">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="w-25">
            <input type="text" class="form-control" placeholder="Type to search by email or name" v-model="pagination.search" @keyup="manualSearch">
          </div>
        </div>
        <table class="table table-bordered table-sm">
          <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, key) in arrayUsers" :key="key">
            <td>
              <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.name}}</p>
            </td>
            <td>
              <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.email}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{item.status?'ACTIVE':'INACTIVE'}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
            </td>
            <td>
              <button class="btn btn-danger btn-sm mb-0" @click="remove(item)">change status</button>
              <button class="btn btn-success btn-sm mb-0" @click="view(item)">view</button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between">
          <div>
            <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
          </div>
          <div>
            <v-pagination
                v-model="pagination.page"
                :pages="pagination.pages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="reloadPaginate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";


export default {
  name: "index",
  data: () => ({
    arrayUsers: [],
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 10,
      search: '',
      totalRows: 0
    }
  }),
  mounted() {
    this.getData()
  },
  methods: {
    reloadPaginate(page){
      this.pagination.page = page
      this.getData()
    },
    manualSearch(){
      clearTimeout(this.pagination.searchTimer);
      this.pagination.searchTimer = setTimeout(() => {
        this.reloadPaginate(1)
      }, 2000);
    },

    async getData(){
      const {data} = await get(`users/find-all?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}`,null, true)
      this.arrayUsers = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages
    },
    async remove(item){
      this.$swal({
        html: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("users/remove",{_id: item._id}, true)
          this.$swal({
            html: 'Process successfully',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          await this.getData()
        }
      })
    },
    view(item){
      this.$router.push({name: 'users-view', query: {id: item._id}})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
