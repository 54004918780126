import { createRouter, createWebHistory } from "vue-router";
import Users from "@/views/Users/index.vue";
import UsersCreate from "@/views/Users/create.vue";
import UsersDetail from "@/views/Users/detail.vue";
import Login from "@/views/SignIn.vue";
import authGuard from "@/router/authGuard";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
    meta: {
      auth: false,
      roles: ['administrator']
    },
  },
  {
    path: "/users",
    name: "users",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: Users,
  },
  {
    path: "/users-create",
    name: "users-create",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: UsersCreate
  },
  {
    path: "/users-view",
    name: "users-view",
    meta: {
      auth: true,
      roles: ['administrator']
    },
    component: UsersDetail
  },


  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
      roles: ['administrator']
    },
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});
router.beforeEach(authGuard);
export default router;
