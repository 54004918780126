/* tslint:disable */
const aesjs = require('aes-js');
const key = [25, 34, 54, 98, 13, 45, 47, 65, 15, 53, 87, 74, 46, 63, 29, 15];


const encrypt = function (text) {
  if(!text) return
  const textBytes = aesjs.utils.utf8.toBytes(text)
  const aesCtr = new aesjs.ModeOfOperation.ctr(key)
  const encryptedBytes = aesCtr.encrypt(textBytes)
  return aesjs.utils.hex.fromBytes(encryptedBytes)
}
const decrypt = function decrypt(text) {
  if(!text) return
  const encryptedBytes = aesjs.utils.hex.toBytes(text);
  const aesCtr = new aesjs.ModeOfOperation.ctr(key);
  const decryptedBytes = aesCtr.decrypt(encryptedBytes);
  const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  return (decryptedText);
}
export {encrypt, decrypt}
