import {decrypt} from "@/utils/encrypt";

export const userCan = (types) => {
    if (!Array.isArray(types)) types = [types];
    const userType = getDataStorage();
    if (types && userType) {
        return types.some(e => userType.includes(e));
    }else
    return false;
};

const getDataStorage = () => {
    if (localStorage.getItem('shipping-admin-type')) {
        const data = localStorage.getItem('shipping-admin-type');
        return decrypt(data);
    } else return null;
};
