import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import moment from "moment";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {userCan} from "@/utils/utils";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import CKEditor from '@mayasabha/ckeditor4-vue3';

const app = createApp(App);
app.config.globalProperties.$filters = {
    formatDate(value) {
        if (!value) return "";
        if (moment(value).isValid()) {
            return moment(value).format('DD-MM-yyyy')
        } else {
            return value;
        }
    },
    formatBoolean(value){
        return value ? "SI" : "NO";
    },
    formatNumber(value) {
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            useGrouping: true,
        });
        return formatter.format(value).replace(/,/g, '.');
    }
}

app.config.globalProperties.$userCan = role => userCan(role);
app.use(store);
app.use(router);
app.use(SoftUIDashboard);
app.use(VueSweetalert2);
app.use( CKEditor );
app.component('v-pagination', VPagination);
app.mount("#app");
